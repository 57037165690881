import React from "react";
import { Helmet } from "react-helmet";

import { Address } from "@legup/legup-model";

import { Theme, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import strings from "../../infra/constants/strings";
import { renderStringWithHTML } from "../../infra/utils";

import ProviderLogo from "../Provider/ProviderLogo";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface FormHeaderProps {
  address: Address;
  name: string;
  shortcode?: string;
  entity: string;
  doneState: boolean;
  providers: any;
  logo_url?: string;
  header?: string;
}

const FormHeader = (props: FormHeaderProps) => {
  const classes = useStyles(props);

  const renderTags = () => {
    // If no city or name just return null
    if (!props.address?.city || !props.name) {
      return null;
    }

    // Get canonical URL - lower-case, with dashes in place of spaces
    let url: string;
    let description: string;

    if (props.entity === "partner") {
      url = `${window.location.origin}/${props.shortcode || ""}/signup`;
      description = strings.signup.metaDescriptionPartner.replace("{City}", props.address.city).replace("{Name}", props.name);
    }
    else {
      const city = props.address.city.replace(/\s/g, "-").replace(/[^a-z-]/gi, "").toLowerCase();
      const name = props.name.replace(/\s/g, "-").replace(/[^a-z-]/gi, "").toLowerCase();
      
      url = `${window.location.origin}/childcare/${name}-${city}/waitlist`;
      description = strings.signup.metaDescription.replace("{City}", props.address.city).replace("{Name}", props.name);
    }

    const markup: any = [{
      "@context": "https://schema.org",
      "@type": "ChildCare",
      "@id": url,
      name: props.name,
      description,
      url,
      image: props.logo_url,
    }];

    // Add address if available
    if (props.address.street_address_1) {
      markup[0].address = {
        "@type": "PostalAddress",
        streetAddress: props.address.street_address_2
          ? `${props.address.street_address_1}, ${props.address.street_address_2}`
          : props.address.street_address_1,
        addressLocality: props.address.city,
        addressRegion: props.address.state,
        postalCode: props.address.postal_code,
        addressCountry: {
          "@type": "Country",
          name: "US",
        },
      };
    }

    // See if we have a public phone number - if multiple centers, just grab the first one
    const provider = (props.providers || []).find(p => p.centers.find(c => !!c.public_phone_number));
    if (provider) {
      markup[0].telephone = provider.centers.find(c => !!c.public_phone_number).public_phone_number;
    }

    return (
      <Helmet>
        <meta property="og:site_name" content="LegUp" />
        <meta property="og:type" content="website" />
        <meta name="og:title" content={strings.signup.metaTitle.replace("{Name}", props.name)} />
        <meta property="og:image" content="https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg" />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
        <script type="application/ld+json">
          {JSON.stringify(markup)}
        </script>
      </Helmet>
    );
  };

  const renderHeader = () => (
    <>
      {props.logo_url ? (
        <>
          <Grid container justify="center" spacing={2}>
            <ProviderLogo logoUrl={props.logo_url} />
          </Grid>
          <Grid container justify="center" spacing={2} className={classes.image}>
            <div style={{ marginRight: "8px" }}>{strings.signup.poweredBy}</div>
            <img
              src="https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg"
              height="20"
              alt={strings.altLogo}
            />
          </Grid>
        </>
      )
        : (
          <Grid container justify="center" spacing={2} className={classes.image}>
            <img
              src="https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg"
              height="60"
              alt={strings.altLogo}
            />
          </Grid>
        )}
      {(!props.doneState && props.header)
        ? renderStringWithHTML(props.header)
        : renderStringWithHTML(((props.entity === "partner") ? strings.signup.headerPartner : strings.signup.header).replace("{Name}", props.name))}
    </>
  );

  return (
    <>
      {renderTags()}
      {renderHeader()}
    </>
  );
};

export default FormHeader;
