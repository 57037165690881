import querystring from "querystring";

import React from "react";

import { Child, Navigator, Parent, WaitlistSpot } from "@legup/legup-model";

// Note that this is an anonymous page, so we cannot use EventControls

import { Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { Alert, EnterNavigator } from "@legup/legup-react-components";

import strings from "../../infra/constants/strings";
import { Event, trackEvent, trackEventLegacy } from "../../infra/tracking";
import { isEmailValid } from "../../infra/utils";
import { CenterForm, CenterFormResult } from "../Center/CenterForm";

const useStyles = makeStyles((theme: Theme) => ({
  hideButton: {
    display: "none",
  },
}));

type FormFamilyInfoProps = {
  form: any;
  child?: Child;
  parents?: Parent[];
  email?: string;
  center: string;
  allowNavigator: boolean;
  navigator?: Navigator;
  birthDate?: Date;
  buttonText: string;
  questionList?: any[];
  questionSectionHeader?: string;
  collapsableSections?: Array<"parent" | "parent2" | "child" | "subsidy" | "questions" | "spot">,
  onSave: (parents: Parent[], child: Child, preferredDate: Date, comments: string, custom_answers: any) => void;
};

const FormFamilyInfo = (props: FormFamilyInfoProps) => {
  const classes = useStyles(props);
  const [child, setChild] = React.useState(props.child ? props.child.copy() : undefined);
  const [parents, setParents] = React.useState(props.parents ?
    ((props.parents.length > 1)
      ? [props.parents[0].copy(), props.parents[1].copy()] : [props.parents[0].copy(), new Parent()])
    : undefined);
  const [spot, setSpot] = React.useState(new WaitlistSpot());
  const [navigator_id, setNavigatorId] = React.useState<string>(props.navigator?.getId());
  const [designee, setDesignee] = React.useState<string>(props.navigator?.getEmail());
  const [allowNoContact, setAllowNoContact] = React.useState(props.navigator?.getAccess() === "full");
  const [savedItems, setSavedItems] = React.useState<any>();
  const [alertMessage, setAlertMessage] = React.useState<string>();

  const formEl = React.useRef(null);

  React.useEffect(() => {
    if (!props.child && props.birthDate && !isNaN(props.birthDate.getTime())) {
      // Use the birthdate that they passed from the enrollment page .. it'll be seen as a nice touch
      const c: Child = new Child();
      c.setBirthDate(props.birthDate);
      setChild(c);
    }

    rehydrateForm(props.center);
  }, [props.center, props.birthDate]);

  React.useEffect(() => {
    setNavigatorId(props.navigator?.getId());
    setDesignee(props.navigator?.getEmail());
    setAllowNoContact(props.navigator?.getAccess() === "full");
  }, [props.navigator]);

  const rehydrateForm = (center: string): any | undefined => {
    if (window === undefined) {
      return;
    }

    // We only look at and write to the family-specific data
    const form = window.sessionStorage.getItem("_family");
    const d = form ? JSON.parse(form) : {};
    setSavedItems(d);

    if (!d.parent || !d.child || !d.preferredDate) {
      // No form to rehydrate - callback as initial visit
      return;
    }

    const savedParents: Parent[] = [new Parent(), new Parent()];
    const savedChild: Child = new Child();

    savedParents[0].buildFromJSON(d.parent);
    savedParents[1].buildFromJSON(d.parent2);
    savedChild.buildFromJSON(d.child);

    setParents(savedParents);
    setChild(savedChild);
    setNavigatorId(d.navigator_id);

    const s = spot.copy();
    s.setPreferredDate(new Date(d.preferredDate));
    s.setComments(d.comments);
    s.setCustomAnswers(d.custom_answers);
    s.setReferral(d.referral);
    setSpot(s);
  };

  const onClickNext = () => {
    trackEvent(Event.buttonClicked, {
      event_description: Event.formButtonNext,
      user_type: "parent",
      button_label: props.buttonText,
      button_location: "waitlist multi-step form",
    });
    formEl.current.click();
  };

  const onFormEvent = (event: Event, metadata: string) => {
    trackEvent(Event.formUpdated, {
      event_description: event,
      form_name: "waitlist multi-step",
      user_type: "parent",
      metadata,
    });
  };

  const checkName = async (email: string): Promise<{navigator_id: string, name: string, access: "full" | "signup"} | undefined> => {
    const queryParams = querystring.stringify({ name: email });
    const resp = await fetch(`/api/navigator/find?${queryParams}`);
    if (resp.status === 200) {
      const data = await resp.json();
      if (data.success) {
        return { navigator_id: data.navigator_id, name: data.name, access: data.access };
      }

    }
  };

  const onSaveForm = async (result: CenterFormResult) => {
    if (!allowNoContact) {
      // Wait ... first let's make sure that this
      if (!await isEmailValid(result.parents[0]?.email)) {
        trackEventLegacy("anonymous", "parent", Event.invalidEmail, result.parents[0].email);
        setAlertMessage(strings.errorCodes.INVALIDEMAIL);

        return;
      }
    }

    // Save to storage to pass to the next page
    if (window !== undefined) {
      const data = { ...savedItems };
      data.parent = result.parents[0];
      data.parent2 = result.parents[1];
      data.child = result.child;
      data.comments = result.spot.getComments();
      data.preferredDate = result.spot.getPreferredDate();
      data.referral = result.spot.getReferral();
      data.custom_answers = result.spot.getCustomAnswers();
      data.designee = designee;
      if (navigator_id) {
        data.parent?.setNavigators([navigator_id]);
        data.parent2?.setNavigators([navigator_id]);
      }

      window.sessionStorage.setItem("_family", JSON.stringify(data));
    }

    props.onSave(result.parents, result.child, result.spot.getPreferredDate(), result.spot.getComments(), result.spot.getCustomAnswers());
  };

  const onSetNavigator = (navigator: Navigator) => {
    setNavigatorId(navigator.getId());
    setDesignee(navigator.getEmail());
    setAllowNoContact(navigator.getAccess() === "full");
    setAlertMessage(strings.signup.navigatorWelcome.replace("{Navigator}", navigator.getName()));
  };

  return (
    <>
      {!!props.allowNavigator && !props.navigator && (
        <EnterNavigator
          onSelected={onSetNavigator}
          onCheckName={checkName}
        />
      )}
      <CenterForm
        saveButtonRef={formEl}
        questionList={props.questionList || []}
        questionSectionHeader={props.questionSectionHeader}
        availableSubsidies={props.form.subsidies}
        allowChangeAddDate={false}
        form="waitlist"
        child={child}
        parents={parents}
        forceParentEmail={props.email}
        spot={spot}
        disallowOther={!!props.form.hide_other_gender}
        requiredFields={allowNoContact ? ["parentname", "questions"] : ["email", "phone", "address", "parentname", "questions"]}
        collapsableSections={props.collapsableSections}
        onEvent={onFormEvent}
        onSave={onSaveForm}
      />
      <Button id="page2Button" onClick={onClickNext} fullWidth variant="contained" color="secondary">
        {props.buttonText}
      </Button>
      <Alert
        title={strings.alertTitle}
        description={alertMessage}
        open={!!alertMessage}
        onClose={() => setAlertMessage(undefined)}
      />
    </>
  );
};

export default FormFamilyInfo;
